import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import axios from "../axiosconfig/axiosconfig";
import CustomNavbar from "../CustomNavbar/CustomNavbar";
import HtmlParser from 'react-html-parser';
import { Card as BootstrapCard, Button, Col, Row, Modal, Form, Alert } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from "./Add.module.css";
import RichTextEditor from "react-rte";

function Card({ name, phone, email, designations, department, onClickEdit, onClickDelete }) {
    return (
        <div className={styles.card}>
            <h4 className={styles.cardTitle}>{name}</h4>
            <p className={styles.cardText}><strong>Phone:</strong> {phone}</p>
            <p className={styles.cardText}><strong>Email:</strong> {email}</p>
            <p className={styles.cardText}><strong>Designations:</strong> {designations}</p>
            <p className={styles.cardText}><strong>Department:</strong> {department}</p>
            <Button variant="primary" onClick={onClickEdit}>Edit</Button>
            <Button variant="danger" onClick={onClickDelete}>Delete</Button>
        </div>
    );
}

// Card.propTypes = {
//     name: PropTypes.string.isRequired,
//     phone: PropTypes.string.isRequired,
//     email: PropTypes.string.isRequired,
//     designations: PropTypes.string.isRequired,
//     department: PropTypes.string.isRequired,
//     onClickEdit: PropTypes.func.isRequired,
//     onClickDelete: PropTypes.func.isRequired,
// };


function Add() {
    const { id } = useParams();
    const [taskData, setTaskData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [editTaskId, setEditTaskId] = useState(null);
    const [deleteTaskId, setDeleteTaskId] = useState(null);
    const [formValues, setFormValues] = useState({
        title: "",
        description: RichTextEditor.createEmptyValue(),
        startDate: "",
        endDate: "",
        isCompleted: false,
        attachments: [],
    });

    const fetchTaskData = async () => {
        try {
            const response = await axios.get(`/users/tasks/${id}`);
            setTaskData(response.data);
            setLoading(false);
        } catch (error) {
            console.error("There was an error fetching the task data:", error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTaskData();
    }, [id]);

    const handleAddTask = async () => {
        if (!formValues.title || !formValues.description || !formValues.startDate || !formValues.endDate) {
            setAlertMessage("All fields are required.");
            return;
        }

        const formData = new FormData();
        formData.append("title", formValues.title);
        formData.append("description", formValues.description.toString("html"));
        formData.append("startDate", formValues.startDate);
        formData.append("endDate", formValues.endDate);
        formData.append("isCompleted", formValues.isCompleted);
        formValues.attachments.forEach(file => formData.append("attachments", file));

        try {
            await axios.post("/tasks", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            setIsModalVisible(false);
            fetchTaskData();
        } catch (error) {
            console.error("Error adding task:", error);
        }
    };

    const handleEditTask = async () => {
        if (!formValues.title || !formValues.description || !formValues.startDate || !formValues.endDate) {
            setAlertMessage("All fields are required.");
            return;
        }

        const formData = new FormData();
        formData.append("title", formValues.title);
        formData.append("description", formValues.description.toString("html"));
        formData.append("startDate", formValues.startDate);
        formData.append("endDate", formValues.endDate);
        formData.append("isCompleted", formValues.isCompleted);
        formValues.attachments.forEach(file => formData.append("attachments", file));

        try {
            await axios.put(`/tasks/${editTaskId}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            setIsEditModalVisible(false);
            fetchTaskData();
        } catch (error) {
            console.error("Error editing task:", error);
            setAlertMessage("There was an error updating the task.");
        }
    };

    const handleDeleteTask = async () => {
        try {
            await axios.delete(`/tasks/${deleteTaskId}`);
            setIsDeleteModalVisible(false);
            fetchTaskData();
        } catch (error) {
            console.error("Error deleting task:", error);
        }
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormValues({
            ...formValues,
            [name]: type === "checkbox" ? checked : value,
        });
    };

    const handleDescriptionChange = (value) => {
        setFormValues({
            ...formValues,
            description: value,
        });
    };

    const openEditModal = (task) => {
        setEditTaskId(task._id);
        setFormValues({
            title: task.title,
            description: RichTextEditor.createValueFromString(task.description, "html"),
            startDate: task.startDate,
            endDate: task.endDate,
            isCompleted: task.isCompleted,
            attachments: task.attachments || [],
        });
        setIsEditModalVisible(true);
    };

    const openDeleteModal = (taskId) => {
        setDeleteTaskId(taskId);
        setIsDeleteModalVisible(true);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!taskData || taskData.length === 0) {
        return <div><center><strong>
            <CustomNavbar />

            <br />
            <br />
            <br />
            No Task's found.</strong></center></div>;
    }


    return (
        <>
            <CustomNavbar />

            <br />
            <br />
            <br />
            {/* <Button className={styles['buttons']} variant="primary" onClick={() => setIsModalVisible(true)}>Add Task</Button> */}
            <Row className="my-3" style={{ margin: '10px' }}>
                {taskData.map((task, index) => (
                    <Col xs={12} key={task._id}>
                        <BootstrapCard className={styles['task-card']}>
                            <BootstrapCard.Header className={styles['task-card-header']}>
                                <span>{`Task ${index + 1}`}</span>
                                <div className={styles['task-card-buttons']}>
                                    {/* <Button variant="primary" onClick={() => openEditModal(task)}>Edit</Button>
                                    <Button variant="danger" onClick={() => openDeleteModal(task._id)}>Delete</Button> */}
                                </div>
                            </BootstrapCard.Header>
                            <BootstrapCard.Body>
                                <BootstrapCard.Text className={styles['task-card-completed']}>
                                    <strong>Completed:</strong> {task.isCompleted ? '✅' : '❌'}
                                </BootstrapCard.Text>
                                <BootstrapCard.Text className={styles["task-card-title"]}>
                                    {task.title}
                                </BootstrapCard.Text>
                                <BootstrapCard.Text className={styles['task-card-content']}>
                                    {HtmlParser(task.description)}
                                </BootstrapCard.Text>
                                <BootstrapCard.Text className={styles['task-card-dates']}>
                                    <strong>Start Date:</strong> {new Date(task.startDate).toLocaleDateString()}
                                </BootstrapCard.Text>
                                <BootstrapCard.Text className={styles['task-card-dates']}>
                                    <strong>End Date:</strong> {new Date(task.endDate).toLocaleDateString()}
                                </BootstrapCard.Text>

                            </BootstrapCard.Body>
                        </BootstrapCard>
                    </Col>
                ))}
            </Row>



            <Modal show={isModalVisible} onHide={() => setIsModalVisible(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Task</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {alertMessage && (
                        <Alert variant="danger" onClose={() => setAlertMessage("")} dismissible>
                            {alertMessage}
                        </Alert>
                    )}
                    <Form>
                        <Form.Group controlId="formTitle">
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                type="text"
                                name="title"
                                value={formValues.title}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formDescription">
                            <Form.Label>Description</Form.Label>
                            <RichTextEditor
                                value={formValues.description}
                                onChange={handleDescriptionChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="formStartDate">
                            <Form.Label>Start Date</Form.Label>
                            <Form.Control
                                type="date"
                                name="startDate"
                                value={formValues.startDate}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formEndDate">
                            <Form.Label>End Date</Form.Label>
                            <Form.Control
                                type="date"
                                name="endDate"
                                value={formValues.endDate}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setIsModalVisible(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleAddTask}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={isEditModalVisible} onHide={() => setIsEditModalVisible(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Task</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {alertMessage && (
                        <Alert variant="danger" onClose={() => setAlertMessage("")} dismissible>
                            {alertMessage}
                        </Alert>
                    )}
                    <Form>
                        <Form.Group controlId="formTitle">
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                type="text"
                                name="title"
                                value={formValues.title}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formDescription">
                            <Form.Label>Description</Form.Label>
                            <RichTextEditor
                                value={formValues.description}
                                onChange={handleDescriptionChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="formStartDate">
                            <Form.Label>Start Date</Form.Label>
                            <Form.Control
                                type="date"
                                name="startDate"
                                value={formValues.startDate}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formEndDate">
                            <Form.Label>End Date</Form.Label>
                            <Form.Control
                                type="date"
                                name="endDate"
                                value={formValues.endDate}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formIsCompleted">
                            <Form.Check
                                type="checkbox"
                                label="Completed"
                                name="isCompleted"
                                checked={formValues.isCompleted}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setIsEditModalVisible(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleEditTask}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={isDeleteModalVisible} onHide={() => setIsDeleteModalVisible(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Task</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this task?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setIsDeleteModalVisible(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleDeleteTask}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Add;
