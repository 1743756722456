import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { publicRoutes } from './routes/publicRoutes';
import { privateRoutes } from './routes/privateRoutes';
import NotFound from './pages/NotFound/NotFound';

function App() {
  const userRole = localStorage.getItem('role');
  const allowedRoles = ['intern', 'manager', 'admin'];

  return (
    <Router>
      <Routes>
        {publicRoutes.map((route) => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))}
        {allowedRoles.includes(userRole) ? (
          privateRoutes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={
                route.roles.includes(userRole) ? (
                  route.element
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
          ))
        ) : (
          <Route path="*" element={<Navigate to="/login" />} />
        )}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
