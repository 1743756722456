import React, { useState, useEffect } from "react";
import axios from "../axiosconfig/axiosconfig"; // Use the configured axios instance
import { Button, Container, Row, Col, Form, Alert } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import "./Login.module.css";
import building from "../building/building.jpg";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [role, setRole] = useState(null);
  const navigate = useNavigate();



  

  useEffect(() => {
    if (role) {
      if (role === "intern") {
        navigate("/intern");
      } else if (role === "admin") {
        navigate("/admin");
      } else if (role === "manager") {
        navigate("/manager");
      } else {
        setError("Unknown role. Unable to navigate.");
      }
    }
  }, [role, navigate]);



  const handleLogin = async () => {
    setError("");
    try {
      const response = await axios.post("/login", {
        email: email,
        password: password,
      });

      console.log("Response data:", response.data);

      if (response.status === 200) {
        const { role, name } = response.data;
        console.log("Role:", role);

        localStorage.setItem("role", role); // Store the role in localStorage
        localStorage.setItem("email", email);
        localStorage.setItem("name", name);

        setRole(role); // Update the role state to trigger useEffect
      } else {
        setError("Login failed. Please check your credentials.");
      }
    } catch (err) {
      console.error(
        "Error during login:",
        err.response?.data?.message || err.message || err
      );
      setError(
        err.response?.data?.message ||
          "Login failed. Please check your credentials."
      );
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Container fluid>
      <Row>
        <Col
          md={6}
          className="d-flex flex-column align-items-center justify-content-center vh-100"
        >
          <h1>Login Page</h1>
          <br></br>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form className="w-100" style={{ maxWidth: "400px" }}>
            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formPassword" className="mt-3">
              <Form.Label>Password</Form.Label>
              <div className="d-flex">
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Button
                  variant="outline-secondary"
                  onClick={togglePasswordVisibility}
                  className="ms-2"
                >
                  <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                </Button>
              </div>
            </Form.Group>
            <Button
              variant="primary"
              onClick={handleLogin}
              className="mt-3 w-100"
            >
              Login
            </Button>
            <div className="mt-3 text-center">
              <Link className="links1" to="/forget-password">
                Forget Password?
              </Link>
            </div>
            <div className="mt-3 text-center">
              <Link className="links1" to="/">
                Back to Home
              </Link>
            </div>
          </Form>
        </Col>
        <Col
          md={6}
          className="d-flex align-items-center justify-content-center p-0"
        >
          <img
            src={building}
            alt="Intern Tracker"
            className="img-fluid w-100 vh-100"
            style={{ objectFit: "cover" }}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
