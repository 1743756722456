import React, { useState, useEffect } from "react";
import axios from "../axiosconfig/axiosconfig";
import { Button, Container, Form, Alert, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import styles from "./Forget.module.css";

const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (message || error) {
      const timer = setTimeout(() => {
        setMessage("");
        setError("");
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [message, error]);

  const handleForgetPassword = async () => {
    setLoading(true);
    try {
      const response = await axios.post("/forgot-password", {
        email: email,
      });
      if (response.status === 200) {
        setMessage("Password Reset Email sent.");
        setError("");
      } else {
        setError("Failed to send reset email.");
        setMessage("");
      }
    } catch (err) {
      setError(err.response?.data?.message || "Failed to send reset email.");
      setMessage("");
    }
    setLoading(false);
  };

  return (
    <Container className={styles.container}>
      <h1>Forget Password</h1>
      <br></br>
      {message && (
        <Alert variant="success" className={styles.alert}>
          {message}
        </Alert>
      )}
      {error && (
        <Alert variant="danger" className={styles.alert}>
          {error}
        </Alert>
      )}
      <Form className={styles.form}>
        <Form.Group controlId="formEmail" className={styles["form-group"]}>
          <Form.Control
            type="email"
            placeholder="Enter your valid email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Group>
        <Button
          variant="primary"
          onClick={handleForgetPassword}
          className={styles.button}
          disabled={loading}
        >
          {loading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className={styles.spinner}
            />
          ) : (
            "Send"
          )}
        </Button>
        <br></br>
        <br></br>
        <center>
          <h6>OR</h6>
        </center>
        <Button variant="success" className={styles.button}>
          <Link className={styles.links} to="/login">
            Login
          </Link>
        </Button>
      </Form>
    </Container>
  );
};

export default ForgetPassword;
